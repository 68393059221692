.logo {
  background-color: white;
  padding: 5px 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
}

:root {
  --dynamic-dots-color: #000;
}

::-webkit-scrollbar {
  width: 3px;
  /* margin-right: 2px; */
}

::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e7e7e;
  /* width: 1px; */
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

.custom_buttons {
  height: 67px;
  line-height: 67px;
}

.main_outer_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  grid-template-rows: 1fr 160px;
}
.main-container {
  margin: 100px 140px 0px;
}
.logo .logo-img {
  width: 180px;
  height: 50px;
}
.header-right .active {
  padding: 8px 40px;
  border-radius: 25px;
  background-color: black;
  color: aliceblue;
  font-family: Dimbo;
}
.header-right .header-right-btn {
  padding: 8px 20px;
  border-radius: 25px;
  background-color: white;
  color: black;
  /* font-weight: bold; */
  cursor: pointer;
  font-family: Dimbo;
}

.profile-dropdown {
  background-color: transparent;
  color: black;
  border-color: lightgray;
}

.profile-section:focus {
  background-color: lightgray;
  border-color: gray;
  color: black;
}

.navbar {
  background-color: #92cfd5;
}

.nav {
  background-color: #92cfd5;
  padding: 10px 140px;
}
.header-link {
  margin: 0 35px;
  color: rgba(30, 30, 30, 0.5);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}
.header-link-active {
  padding: 0 35px;
  color: rgba(30, 30, 30, 1);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.sidebar-sorting-buttons-container .sidebar-sorting-buttons {
  display: none;
}
.sidebar-sorting-buttons-container:hover .sidebar-sorting-buttons {
  display: block;
}
.navi-link {
  margin-right: 35px;
  color: rgba(30, 30, 30, 0.5);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}
.navi-link-active {
  padding-right: 35px;
  color: rgba(30, 30, 30, 1);
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin: 30px auto 0px;
  margin-bottom: 20px;
}
.text-header {
  font-size: 40px;
  font-weight: bolder;
  margin: 40px 0px;
  font-family: Dimbo;
  text-align: center;
}
.fields {
  margin-top: 25px;
  position: relative;
  border-radius: 30px;
  width: 100%;
  border: 1px solid #92cfd5;
  box-sizing: border-box;
}

.checkoutinput-field {
  width: 100%;
  padding: 16px;
  border: none !important;
  outline: none !important;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 14px;
}
.checkoutfields {
  margin-top: 25px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #92cfd5;
  box-sizing: border-box;
}

.cardfields {
  margin-top: 25px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #92cfd5;
  box-sizing: border-box;
}
.inputlabel {
  position: absolute;
  bottom: 47px;
  left: 35px;
  padding: 0 2px;
  background-color: #ffff;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.checkoutinputlabel {
  position: absolute;
  bottom: 43px;
  left: 20px;
  padding: 0 2px;
  background-color: #ffff;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.cardinputlabel {
  position: absolute;
  bottom: 41px;
  left: 20px;
  padding: 0 2px;
  background-color: #ffff;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.cardinput-field {
  width: 100%;
  padding: 16px;
  border: none !important;
  outline: none !important;
  border-radius: 30px;
  box-sizing: border-box;
}

.input-field {
  width: 100%;
  padding: 16px;
  border: none !important;
  outline: none !important;
  border-radius: 30px;
  box-sizing: border-box;
}
.input-field:focus {
  border: none !important;
  outline: none !important;
}

.button {
  margin: 20px 0px;
  background-color: black;
  color: #ffff;
  font-weight: bold;
  padding: 16px 0;
  width: 100%;
  border-radius: 30px;
  /* box-sizing: border-box; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
}
.google-btn {
  margin: 10px;
  border: 1px solid #92cfd5;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0px 10px 20px 0px rgba(131, 119, 198, 0.11);
}
.google-btn-microsoft {
  margin: 10px;
  border: 1px solid #92cfd5;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0px 10px 20px 0px rgba(131, 119, 198, 0.11);
}
.google-btn-microsoft > div {
  width: 100%;
}
.password-fields {
  margin-top: 25px;
  position: relative;
  border-radius: 30px;
  width: 100%;
  border: 1px solid #92cfd5;
  box-sizing: border-box;
}
.password-label {
  position: absolute;
  bottom: 40px;
  padding: 0 2px;
  left: 35px;
  background-color: #ffff;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.password-input {
  border: none !important;
  width: 100%;
  padding: 16px;
  outline: none !important;
  border-radius: 30px;
  box-sizing: border-box;
}
.password-input:focus {
  border: none !important;
}

.forgot-password {
  margin: 5px 0;
  display: flex;
  justify-content: end;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}
.inputStyle {
  width: 4rem !important;
  height: 4rem;
  margin: 0 1rem;
  font-size: 32px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  caret-color: transparent;
}
.inputStyle:focus {
  border: 1px solid #92cfd5 !important ;
}
.show-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ccc;
  padding: 2px 14px;
  border-radius: 20px;
}

.dropdown_list {
  margin: 5px;
  background-color: white;
  border: 1px solid #eee;
  text-align: start;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.dropdown_list:hover {
  margin: 5;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: start;
  padding: 5;
  border-radius: 5px;
  cursor: pointer;
}
.required-field {
  color: red;
  font-size: 0.75rem !important;
  width: 100%;
  text-align: left;
  margin-left: 40px;
}

/* settings */

/* .setting-tabs-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid gray;
  margin: 10px 0px;
  overflow-x: scroll;
} */
.setting-tabs-container {
  display: flex;
  width: fit-content;
  max-width: 100%;
  border-bottom: 1px solid gray;
  margin: 10px 0px;
  margin: auto;
  overflow: auto;
}

.settings .setting-tabs {
  width: 160px;
  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
  color: rgba(121, 118, 126, 1);
}

.settings .setting-tabs-active {
  width: 160px;
  border-bottom: 2px solid black;
  color: rgba(30, 30, 30, 1);

  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
}

.settings-forms {
  display: flex;
  justify-content: space-between;
}
.settings-forms-single-element {
  padding: 0px 10px;
  width: 50%;
}

.settings-bottom-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.settings-btn-cancle {
  padding: 0px 10px;
}
.settings-profile-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
}

.settings-header {
  display: flex;
  margin: 30px 10px 10px;
  align-items: center;
}

.msg_container {
  display: flex;
  margin: 5px 0px;
  max-width: 60%;
}
.msg_container_static_message {
  display: flex;
  margin: 5px 0px;
  max-width: 60%;
}
/* =================================account======================================= */

#upload input {
  opacity: 0;
}

.microsoft-container {
  width: 100%;
}

.picture-btn {
  display: flex;
  margin-bottom: 8px;
  width: fit-content;
}
.picture-btn-reset {
  border: 1px solid rgb(146, 207, 213);
  padding: 5px 20px;
  border-radius: 20px;
  margin-left: 20px;
  color: rgb(146, 207, 213);
  cursor: pointer;
  font-family: Dimbo;
  width: fit-content;
}

/*================complete-profile=========================*/

.complete-profile {
  padding: 10px 15px 10px;
  font-size: 30px;
  font-family: Dimbo;
  text-align: center;
}

/**************************HomePage*********************************************/

/* .homepage_messages {
  height: 350px;
  overflow: scroll;
} */

.heading_chat_comtainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.heading_chat_comtainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.homepage_slider_time {
  font-size: 16px;
}
.homepage_slider_model_seo {
  font-size: 16px;
  text-decoration: none;
  color: black;
}
.quest_slider_model_seo {
  font-size: 22px;
}
.homepage_slider_model_username {
  font-size: 16px;
}

.homepage_messages_containers::-webkit-scrollbar {
  display: none;
}
.homepage_messages_containers {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.homepage_quest_tags_list {
  display: flex;
  overflow-x: scroll;
  margin: 35px 0px;
}
.homepage_quest_tags_list > img {
  max-width: 100px;
  width: 100px;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
}

.home_messages_container {
  overflow-y: scroll;
  margin-bottom: 10px;
  padding-top: 100px;
  padding-bottom: 150px;
  position: relative;
}

.home_messages_container::-webkit-scrollbar {
}

/* Hide scrollbar for IE, Edge and Firefox */
.home_messages_container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#mynestheads_chatComponent::-webkit-scrollbar {
  display: none;
}

#mynestheads_chatComponent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.homepage_quest_tags_list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.homepage_quest_tags_list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.homepage_footer {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_logo {
  cursor: pointer;
}
.homepage_footer > img {
  max-width: 50%;
  object-fit: contain;
}
.homepage_quest_header_img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.homepage_quest_header_user_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/*=====================================================/*/
.character_listing_container {
  /* margin: 0px 140px; */
  margin-top: 60px;
}

.model-list-outer-container {
  padding: 10px;
}
.model-list-main-container {
  margin: 50px 0px 35px;
}

.model-list-main-loading-container {
  margin: 50px 0px 10px;
  height: 166px;
  display: flex;
}

.model-list-main-container .slick-dots li button::before {
  font-size: 12px;
}
.model-list-main-container .slick-dots li {
  margin: 2px;
}
.model-list-main-container .slick-dots {
  bottom: -42px !important;
}

.model-list-main-container .slick-dots li.slick-active button::before {
  color: var(--dynamic-dots-color);
  opacity: 1;
}
.model-list-main-container .slick-dots li button::before {
  color: var(--dynamic-dots-color);
}



.custom-footer-slider .slider-wrapper {
  overflow: visible;
}

/* .custom-footer-slider .slider-wrapper {
  overflow-y: visible !important;
}

.custom-footer-slider .slick-list {
  overflow-y: visible !important; 
} */

/* .model-list-main-container .slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
  opacity: 1;
}

.model-list-main-container .slick-next::before,
.slick-prev::before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000;
} */

.main_model_dailog_container {
  /* padding: 60px 0px; */
  margin-top: 50px;
}

.model-page-dailoge {
  display: flex;
  width: 60%;
  max-width: 1280px;
  margin: auto;
  background-color: rgb(255, 255, 255);
  padding: 30px;
  border-radius: 40px;
}
.model-page-dailoge .default-dialog-image-container {
  min-width: 150px;
  width: 150px;
  min-height: 150px;
  height: 150px;
  padding: 20px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
}

.default-dailog-character-name {
  font-size: 35px;
}
.default-dailog-character-des {
  font-size: 25px;
  line-height: 28px;
}

.character_category_container {
  padding: 10px 0px;
  margin: 10px;
  border: 1px solid transparent;
  position: relative;
}
.character_category_container_slider {
  padding: 10px 0px;
  margin: 10px 0px;
  border: 1px solid transparent;
  position: relative;
}

.character_category_container_slider .image_container {
  height: 100px;
  /* width: 200px; */
}
.character_category_container .image_container {
  height: 100px;
  width: 200px;
}

.character_category_container.active {
  box-shadow: 0px 0px 10px 1px #ccc;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
}
.character_category_container_slider.active {
  box-shadow: 0px 0px 10px 1px #ccc;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
}

.character_category_container_slider.hover-active {
  box-shadow: 0px 0px 10px 1px #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.character_category_container:hover {
  /* border: 1px solid gray; */
  box-shadow: 0px 0px 10px 1px #ccc;
  border-radius: 10px;
  cursor: pointer;
}
.character_category_container_slider:hover {
  /* border: 1px solid gray; */
  box-shadow: 0px 0px 10px 1px #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.character_listing {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
}
.character_listing:hover {
  outline: 3px solid black;
  border-radius: 25px;
  box-sizing: border-box;
}
.character {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.character-image-wrapper {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
}
.character img {
  max-width: 100%;
  max-height: 100%;
}
.charater_title {
  font-family: Dimbo;
  font-size: 40px;
  line-height: 45px;
}

.character_des {
  color: rgba(30, 30, 30, 0.5);
  width: 100%;
}

.charactor_filter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .character_filter {
  text-align: end;
  border: 2px solid black;
  border-radius: 50px;
  margin: 20px 0px;
  padding: 10px 15px;
  font-family: Dimbo;
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgBrZJtDYAgFEWvJqCBNjCKEYxgBG1gBKMYQRpAA2ygoLAx9+RDPdvdY28cdn9QAKh1BjtNHMzOzZsmUmc0i15nz0znXhYZkvDanBVFolThRqOjApKiJEcXEFtEmAhpQAKMqFwhkdWTFupC+SBy7yxzxCi/ixwfqHH9lBkvYdTyAIOBR2FeBGIAAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 1.1em top 54%, 0 0;
  background-size: 15px auto;
} */
.character_filter {
  border-radius: 40px;
  text-transform: capitalize;
  border: 1px solid #000;
  display: inline-flex;
  padding: 22px 25px 22px 15px;
  padding: 10px 22px;
  background-color: #fff;
  align-items: center;
  gap: 30px;
  color: #1e1e1e;
  font-family: Dimbo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 170px;
  height: 67px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgBrZJtDYAgFEWvJqCBNjCKEYxgBG1gBKMYQRpAA2ygoLAx9+RDPdvdY28cdn9QAKh1BjtNHMzOzZsmUmc0i15nz0znXhYZkvDanBVFolThRqOjApKiJEcXEFtEmAhpQAKMqFwhkdWTFupC+SBy7yxzxCi/ixwfqHH9lBkvYdTyAIOBR2FeBGIAAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: right 1.1em top 54%, 0 0;
  background-size: 15px auto;
  text-align: left;
}

.filter_options {
  text-transform: capitalize;
}

.character_filter img {
  margin-left: 55px;
}

.character_details {
  display: flex;
  border: 1px solid #92cfd5;
  border-radius: 15px;
  margin-top: 130px;
  align-items: center;
}
.character_details .img {
  display: flex;
  margin: 0px 50px;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  max-width: 300px;
  max-height: 300px;
  min-width: 300px;
}

.character_details .img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.character_details .title {
  display: flex;
  font-family: Dimbo;
  font-size: 40px;
}

.character_details_section {
  padding: 0px 30px;
}

.character_details_des {
  color: rgba(30, 30, 30, 0.5);
}
.character_details_star {
  display: flex;
  align-items: center;
  margin: 15px 0px;
  color: rgba(30, 30, 30, 0.5);
}

.character_details_bottom {
  margin: 30px 0px;
}
.single-line {
  white-space: nowrap; /* Prevent wrapping to multiple lines */
  overflow: hidden; /* Hide content that overflows the container */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
}

.about_character {
  display: none;
}

.about_character_active {
  background-color: rgba(146, 207, 213, 1);
  position: absolute;
  left: 60%;
  top: 15%;
  padding: 10px 20px;
  border-radius: 10px;
  width: 350px;
  text-align: start;
}

.chat_img_container {
  position: relative;
  cursor: pointer;
}

.chat_character_image {
  height: 250px;
  margin: auto;
}

.chat_character_image_small {
  max-width: 170px;
  height: 170px;
  margin: auto;
}

.models_loading_container {
  width: 200px;
  height: 150px;
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
  margin-right: 15px;
}

.main_model_image {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

.main_model_image_text {
  font-size: 55px;
  line-height: 60px;
  font-family: Dimbo;
  margin: 0px 40px;
}

.main_model_image_sec_text {
  font-size: 30px;
  font-family: Dimbo;
  margin: 20px 0px;
  text-align: center;
}

/**====================================================================*/

.chat_page {
  margin: 110px 140px 0px;
}

.page_container {
  margin: 70px auto 0px;
  max-width: 1280px;
  width: 90%;
  flex: 1;
}
.chat_page_container {
  margin: 100px auto 0px;
  max-width: 1280px;
  margin-top: 0px !important;
}
.chat_page_container_static {
  margin: 125px 0px 0px;
  padding: 10px 5px 0px;
  margin-top: 10px !important;
}

.subscriptions_page {
  margin: 110px 140px 0px;
}

.main_character_container {
  position: sticky;
  width: 100%;
  left: 0px;
  top: 50px;
  padding: 0;
  background: linear-gradient(to bottom, white, transparent);
  z-index: 1;
  max-height: 230px;
  margin-bottom: 20px;
}
.main_character_container_static {
  position: sticky;
  width: 100%;
  left: 0px;
  top: -20px;
  padding: 0;
  background: linear-gradient(to bottom, white, transparent);
  z-index: 1;
  max-height: 230px;
}

.chat_component_character_listing {
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  background-color: #fff;
  position: relative;
}
.chat_component_character_listing.active {
  background-color: #f23ff7 !important;
}

.start_chat {
  text-align: center;
  padding: 0;
  display: block;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 160px;
}

.starting_box {
  position: relative;
  bottom: 90px;
  background-color: white;
  border: 3px solid black;
  border-radius: 30px;
  text-align: start;
  padding: 40px;
  font-family: Dimbo;
  font-size: 25px;
  margin: 0px 40px;
}

.starting_box_small {
  position: relative;
  bottom: 60px;
  background-color: white;
  border: 3px solid black;
  border-radius: 30px;
  text-align: start;
  padding: 20px 40px;
  font-family: Dimbo;
  font-size: 25px;
  margin: 0px 40px;
}

.chat_field {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 10px 140px 20px;
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
  background-color: rgb(255, 255, 255);
  padding-left: 420px;
}

.input_field_selected_image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.input_field_selected_file {
  width: 130px;
  height: 130px;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  overflow: "hidden";
}
.input_field_selected_video {
  width: 130px;
}

.chat_field_static {
  position: sticky;
  bottom: 0px;
  width: 100%;
  left: 0px;
  padding: 10px 10px 20px !important;
  display: flex;
  justify-content: space-between;
  /* align-items: end; */
  background-color: rgb(255, 255, 255);
  /* padding:  0px !important; */
}

.mynestheads_container {
  margin: 125px auto 0px;
  max-width: 1280px;
  /* margin-top: 10px !important; */
}

.chat_field_details {
  /* position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px; */
  /* padding: 10px 140px 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: 30px;
}
.chat_field_details input {
  width: 100%;
  border-radius: 24px;
  margin-right: 20px;
  border: 1px solid rgba(30, 30, 30, 0.4);
  color: black;
  padding: 10px 30px;
  font-family: Dimbo;
  outline: none;
}
.chat_field input {
  width: 100%;
  border-radius: 25px;
  /* margin: 0 20px; */
  border: 1px solid rgba(30, 30, 30, 0.4);
  color: black;
  padding: 10px 30px;
  font-family: Dimbo;
  outline: none;
}
.chat_field input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
}
.chat_field_details input::-webkit-input-placeholder {
  color: rgba(30, 30, 30, 0.4);
}

.chat_btn {
  background-color: black;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  cursor: pointer;
  overflow: hidden;
}
.add_btn {
  background-color: rgba(159, 205, 212, 0.4);
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  cursor: pointer;
  overflow: hidden;
}
.add_btn_text {
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  /* cursor: pointer; */
  overflow: hidden;
}
.chat_messages_main_container {
  display: flex;
  flex-direction: column;
}

.chat_messages_container {
  margin-bottom: 100px;
  align-self: flex-end;
  width: 100%;
  overflow: hidden;
  /* margin-top: 100px; */
}
.chat_messages_container_static {
  /* margin-bottom: 100px; */
  align-self: flex-end;
  width: 100%;
  overflow: hidden;
  margin-top: 100px;
}

/*************************************************/

.percentage_controller {
  /* display: initial; */
  height: 8px;
  background-color: rgb(238, 238, 238);
  width: 100%;
  border-radius: 5px;
}

.percentage_component {
  padding-right: 10px;
  width: 200px;
}

.star-icon {
  cursor: pointer;
  border-radius: 50%;
  background-color: #d3d3d3;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.star-icon.active {
  background-color: #f23ff7;
}

.star-icon:hover {
  background-color: #f23ff7;
}

.star-icon img {
  width: 30px;
}

/***********************************************************************************/

.accordion-button {
  font-family: Dimbo;
  font-size: 20px;
}
.accordion-button {
  font-family: Dimbo;
  font-size: 20px;
  background-color: white !important;
  color: black !important;
}

/*******************************/

.model_page {
  margin: 100px 140px 0px;
}

.model_not_available {
  width: 300px;
  background-color: rgb(250, 250, 250);
  border-radius: 150px;
  height: 300px;
  margin: 0px auto;
}

.landing_page {
  display: flex;
  height: 100vh;
}

.landing_page .left_side {
  width: 57%;
}
.landing_page .right_side {
  padding: 10px;
  width: 43%;
  background-color: rgb(146, 207, 213);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.landing_page .right_side_waitlist {
  padding: 10px;
  width: 43%;
  background-color: rgb(146, 207, 213);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.landing_page .bottom_text {
  position: absolute;
  bottom: 60px;
}
.landing_page .nestheads_link {
  flex: 1 1 0%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing_page .nestheads_link_text {
  font-family: Dimbo;
  color: black;
  text-decoration: underline;
  font-size: 25px;
  cursor: pointer;
}

.landing_video {
  width: 640px;
  height: 363px;
}

.waitlist_joined {
  /* width: 50%;
  margin-left: 60px; */
  margin: 0 auto;
  padding: 0 60px;
  width: auto;
  text-align: center;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 102;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.model_nestheads {
  width: 50%;
}

.my_nestheads {
  border: 3px solid black;
  padding: 50px;
  margin: 0px 60px;
  border-radius: 20px;
}

.landing_model_data {
  padding: 20px 40px;
  text-align: left;
  max-height: 70vh;
  line-height: 22px;
  overflow-x: scroll;
  margin-right: 1px;
}
.landing_model_data {
  padding: 20px 40px;
  text-align: left;
  max-height: 70vh;
  line-height: 22px;
  overflow-x: hidden;
  margin-right: 1px;
  overflow-y: scroll;
}

/* .plans_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
} */
.plans_container_subscribed {
  width: 400px;
  max-width: 400px;
  margin: auto;
}

.plan_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.plan_container_main {
  flex: 1 1 0%;
  border-radius: 10px;
  border: 1px solid rgb(146, 207, 213);
  background-color: rgba(146, 207, 213, 0.05);
  overflow: hidden;
  margin-bottom: 0px;
}

.plan_container_main_margin {
  margin-bottom: 0px;
}
.plan_container_main_margin_top {
  margin-top: 35px;
}

.cancel_button {
  position: absolute;
  bottom: -100px;
  width: 100%;
  /* z-index: 1; */
}

.custom_model {
  padding: 80px 150px;
  border-radius: 10px;
  background-color: white;
  inset: 0px;
  text-align: center;
  max-width: 768px;
}
.custom_model_quest {
  padding: 80px 150px;
  border-radius: 10px;
  background-color: white;
  inset: 0px;
  text-align: center;
  max-width: 768px;
}

.custom_model_buttons_container {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.payment_history_heading {
  font-family: Dimbo;
  font-size: 35px;
  margin: 120px 0px 30px;
  text-align: center;
}

.Payment_history {
  overflow-x: scroll;
}

.Payment_history th,
td {
  padding: 10px;
  text-align: center;
}
.Payment_history th:last-child,
td:last-child {
  text-align: -webkit-right;
  text-align: -moz-right;
}
.Payment_history th:first-child,
td:first-child {
  text-align: start;
}
.Payment_history td {
  font-size: 14px;
}

.plan_expired {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 20px;
}

.payment_model {
  padding: 25px 20px;
  background-color: white;
  inset: 0px;
  text-align: center;
  /* width: 34%; */
  width: 530px;
  max-width: 530px;
}

.payment_history_margin {
  font-family: Dimbo;
  font-size: 35px;
  margin: 120px 0px 30px;
  text-align: center;
}
.payment_history {
  font-family: Dimbo;
  font-size: 35px;
  margin: 40px 0px 30px;
  text-align: center;
}

.subscriptions_page_plans {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 20px;
}

.dropdown-items {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10;
}
.dropdown-items:hover {
  background-color: #ccc;
}

.dropdown-toggle::after {
  display: none !important;
}

.period_selector_container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.period_selector {
  cursor: pointer;
}
.period_selector_item {
  height: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.period_selector_item_active {
  background-color: rgba(146, 207, 213, 1);
}

.popup_backdrop {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_bar {
  min-width: 320px !important;
  /* position: absolute; */
}
.side_bar_background {
  /* margin-top: 50px; */
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(252, 252, 252, 0.54) !important;
  transition: background-color 0.5s !important;
}
.custom-dropdown-items {
  height: 0px;
  overflow: hidden;
  transition: height 2s ease-in-out;
}

.custom-dropdown-items.active {
  height: auto;
}



/* .slide-pane__overlay.overlay-after-open {
  background-color: transparent !important;
} */

.slide-pane {
  background-color: #000 !important;
}

.slide-pane__overlay {
  z-index: 150;
}

.slide-pane_from_left.content-after-open {
  width: 24% !important;
}

.chatPage_sibebar_container {
  width: 280px;
}

.chatPage_sibebar {
  width: 280px;
  padding: 20px 30px 22vh;
  z-index: 99;
  border-right: 1px solid black;
  position: fixed;
  top: 50px;
  height: 100vh;
  overflow: scroll;
}

.chatPage_sibebar_tagname {
  display: initial;
}

.message_container_landing_left {
  position: relative;
}
.message_container_landing_left::before {
  content: "tABBB";
  border-radius: 2px;
  position: absolute;
  color: rgb(37, 33, 52);
  left: -28px;
  bottom: 0px;
  clip-path: polygon(0 90%, 100% 30%, 100% 100%, 0 100%);
  background-color: rgb(37, 33, 52);
}
.message_container_landing_right {
  position: relative;
}
.message_container_landing_right::after {
  content: "tABBB";
  border-radius: 2px;
  position: absolute;
  color: rgb(159, 205, 212);
  right: -28px;
  bottom: 0px;
  clip-path: polygon(0 30%, 100% 90%, 100% 100%, 0% 100%);
  background-color: rgb(159, 205, 212);
}
.message_img {
  margin: 4px;
  object-fit: cover;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  width: 180px;
  height: 180px;
}

.switch_outer {
  border-radius: 20px;
  box-shadow: rgb(204, 204, 204) 0px 0px 20px 1px;
  height: 25px;
  width: 54px;
  position: relative;
}

.switch_inner {
  background-color: black;
  height: 17px;
  border-radius: 50%;
  margin: 0px;
  width: 17px;
  position: absolute;
  left: 4px;
  top: 4px;
  cursor: pointer;
}

.switch_on {
  animation: switch_on 0.2s forwards;
}
.switch_off {
  animation: switch_off 0.2s forwards;
}

.goal_title {
  font-family: "Dimbo";
  font-size: 20px;
  height: 30px;
  min-width: 80px;
  overflow: hidden;
  width: 250px;
}

/****************Quest*******************/

.quest_tags_list {
  overflow-x: scroll;
  margin: 35px 0px;
}
.quest_tags_list > img {
  max-width: 100px;
  width: 100px;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
}

.quest_header_user_img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  /* border-radius: 50%; */
}
.quest_header_img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.quest_icons > svg {
  width: 25px;
  height: 25px;
}

.questbtn {
  background-color: #d3d3d3;
  padding: 3px 7px;
  border-radius: 20px;
  color: #7e7e7e;
  text-decoration: none;
  position: relative;
  font-family: Dimbo;
}

.questbtn.active {
  background-color: #000;
  color: #fff;
}
.questbtn .countbbl {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #acacac;
  font-size: 8px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 2px solid gray; */
}
.questbtn .countbbl.active {
  background-color: #f23ff7;
  color: #fff;
  /* border: 2px solid #000; */
}
.quest_list_container {
  display: flex;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  /* max-width: 1140px; */
  margin: auto;
  position: relative;
}
.mainslidercontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.slidercontainer {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the animation to the input field */
.blinking-input {
  animation: blink 1s infinite step-end;
  width: 2px;
  background-color: #000;
  height: 25px;
}

.input-range-container {
  width: 80%;
  margin: 20px auto;
  text-align: center;
}

.range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #000000;
  outline: none;
  opacity: 0.7;
  width: 200px;
  transition: opacity 0.15s ease-in-out;
}

.range-slider:hover {
  opacity: 1;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  background-image: url("/src/assets/map_toxic.png");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  background-image: url("/src/assets/map_toxic.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.value {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

/****create character*****/

.create_character {
  font-family: Dimbo;
  margin: auto;
}

.create_character .character_description {
  padding: 10px;
  align-items: center;
}
.create_character .character_description img {
  transform: rotate(270deg);
  width: 25px;
  height: 25px;
}
.create_character .second_container {
  /* border: 1px solid #000;
  border-radius: 10px; */
  padding: 10px;
  margin: 10px 0;
}
.create_character .second_container {
  /* border: 1px solid #000;
  border-radius: 10px; */
  padding: 10px;
  margin: 10px 0;
}

.create_character .character_details {
  max-width: 600px;
  background-color: rgb(255, 255, 255);
  margin: auto;
  border-radius: 40px;
  padding: 30px;
  display: flex;
}
.create_character .main_character_details_container {
  /* background-color: rgb(0, 0, 0); */
  padding: 75px;
}

.create_character .character_details .toggle_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.create_character .character_details .character_image_inner_container {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid rgb(0, 0, 0);
  align-items: center;
  position: absolute;
  width: 160px;
  height: 160px;
  background-color: red;
}

.second_container .toxic_healthy_input_container .character_image_container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.second_container
  .toxic_healthy_input_container
  .character_image_container
  .character_image {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.create_character
  .character_details
  .character_image_inner_container
  .character_image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.second_container .toxic_healthy_input_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 160px;
}

.second_container .character_image_container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.second_container .character_image {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid rgb(0, 0, 0);
  align-items: center;
  position: absolute;
  width: 160px;
  height: 160px;
  background-color: #f23ff7;
}

.second_container .character_image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.main_character_details_container .right_container {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  height: 150px;
  padding-left: 20px;
}

.healthy_toxic_container {
  border: 1px solid #000;
  border-radius: 50px;
  width: fit-content;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.create_nest_heading {
  display: none;
}

.add_items_mainconainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add_items_mainconainer_small {
  display: none;
}
.add_items_mainconainer_small .create_nest_btn {
  cursor: pointer;
  font-size: 30px;
  padding: 10px 40px;
  color: rgb(255, 255, 255);
  text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 0px;
  border: 2px solid rgb(0, 0, 0);
  background-color: red;
  border-radius: 80px;
}
.add_items_mainconainer .add_character_item {
  cursor: pointer;
  font-size: 30px;
  padding: 10px 40px;
  color: rgb(255, 255, 255);
  text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 0px;
  border: 2px solid rgb(0, 0, 0);
  background-color: red;
  border-radius: 80px;
}
.add_items_mainconainer_small .add_character_item {
  cursor: pointer;
  font-size: 30px;
  padding: 10px 40px;
  color: rgb(255, 255, 255);
  text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 0px;
  border: 2px solid rgb(0, 0, 0);
  background-color: red;
  border-radius: 80px;
}

.add_items_mainconainer .left_item {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0px;
  flex: 1 1 0%;
}
.add_items_mainconainer .right_item {
  flex: 1 1 0%;
  display: flex;
  justify-content: flex-end;
}
.healthy_toxic_container .healthy_toxic_buttons {
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding: 6px;
}

.healthy_toxic_container .healthy_toxic_buttons.active {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
}

.upload_character_image {
  border: 2px solid #000;
  border-radius: 50%;
  min-width: 80px;
  width: 80px;
  height: 80px;
  min-height: 80px;
}
.upload_character_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.create_character_input_container {
  background-color: #fff;
  display: flex;
  border: 1px solid lightgray;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 12px;
  align-items: center;
  box-shadow: 0px -2px 10px 0px #f7f7f7;
}
.create_character_input_container input {
  flex: 1;
  border-radius: 10px;
  overflow: unset;
  border: 1px solid lightgray;
  background-color: #eee;
  padding: 20px 10px;
  height: 35px;
  margin-right: 10px;
}

.create_character_fields {
  margin: 4px 0px;
  text-align: center;
}
.create_character_fields input {
  width: 50%;
  border-radius: 10px;
  overflow: unset;
  border: 1px solid #000;
  padding: 0px 10px;
  height: 35px;
}
.create_character_bottom_container {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create_character_bottom_container .left {
  width: 80px;
  min-width: 80px;
}
.create_character_bottom_container .right {
  min-width: 80px;
  width: 80px;
  display: flex;
  justify-content: flex-end;
}
.add_character_item_image_container {
  display: flex;
  align-items: center;
}

.add_character_item_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #000;
  /* padding: 10px; */
  margin-right: 10px;
}
.add_character_item_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.character_items_container {
  width: 70%;
  margin: auto;
  display: flex;
}
input:focus {
  border: 2px solid #000;
  outline: #000;
}
/*/*/

/* CustomSliderWrapper.css */

.slider-wrapper {
  position: relative;
  width: 100%; /* Adjust width as needed */
  margin: 0 auto; /* Center the slider horizontally */
  overflow: hidden; /* Ensure contents don't overflow */
}

.custom-slider {
  width: 100%; /* Full width of the container */
}

.custom-slider-slide {
  outline: none; /* Remove outline on slides for accessibility */
}

.custom-slider-buttons {
  position: absolute; /* Positioning the buttons absolutely */
  top: 50%; /* Align buttons to the center vertically */
  transform: translateY(-50%);
  width: 100%; /* Full width of the container */
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
  padding: 0 20px; /* Adjust padding as needed */
  box-sizing: border-box; /* Ensures padding does not affect width */
}
.character_slider_button_svg svg {
  position: relative !important;
  bottom: 1.5px !important;
}

.custom-prev-button,
.custom-next-button {
  background-color: #000000; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none; /* Remove button border */
  padding: 10px 15px; /* Padding inside the button */
  cursor: pointer; /* Pointer cursor on hover */
  outline: none; /* Remove outline on focus */
  border-radius: 4px; /* Rounded corners */
}

/* Optional: Hover effect for buttons */
.custom-prev-button:hover,
.custom-next-button:hover {
  background-color: #3a3a3a; /* Darker background on hover */
}

.custom-slider-count-component {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 25px;
}


@keyframes switch_on {
  from {
    left: 4px;
  }
  to {
    left: 33px;
  }
}
@keyframes switch_off {
  from {
    left: 33px;
  }
  to {
    left: 4px;
  }
}

/**********/

.wake_up_will_questions::after {
  content: 'q';
  border-radius: 2px;
  position: absolute;
  /* color: rgb(37, 33, 52); */
  /* left: -28px; */
  right: 24%;
  bottom: -38px;
  width: 143px;
  height: 94px;
  clip-path: polygon(70% 58%, 0 58%, 100% 100%);
  background-color: #ccc;
}
/* .message_container_landing_left::before {
    content: "tABBB";
    border-radius: 2px;
    position: absolute;
    color: rgb(37, 33, 52);
    left: -28px;
    bottom: 0px;
    clip-path: polygon(0 90%, 100% 30%, 100% 100%, 0 100%);
    background-color: rgb(37, 33, 52);
} */

/* .popup {
  display: none; 
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 9999;
} */

@media (max-width: 1600px) {
  .quest_list_container {
    /* width: 700px; */
  }
  .chat_page_container {
    margin: 100px 60px 0px;
  }
  .mynestheads_container {
    margin: 125px 60px 0px;
    max-width: 1280px;
  }
}
@media (max-width: 1380px) {
  .quest_list_container {
    /* width: 600px; */
  }
  .slide-pane_from_left.content-after-open {
    width: 40% !important;
  }
  .page_container {
    /* margin: 125px 140px 0px; */
  }
}

@media (max-width: 1280px) {
  .quest_list_container {
    /* width: 500px; */
  }
  .quest_tags_list {
    overflow-x: scroll;
    margin: 25px 0px;
  }
  .quest_tags_list > img {
    max-width: 80px;
    width: 80px;
    height: 80%;
    object-fit: contain;
    margin-right: 40px;
  }

  .chat_field {
    padding: 10px 60px 20px;
    padding-left: 340px;
  }
  .slide-pane_from_left.content-after-open {
    width: 35% !important;
  }
  .landing_video {
    width: 480px;
    height: 270px;
  }
  .model_nestheads {
    width: 60%;
  }
  .page_container {
    /* margin: 125px 120px 0px; */
  }
  .chat_page_container {
    margin: 100px 140px 0px;
  }
  .mynestheads_container {
    margin: 125px 20px 0px;
  }
}
@media (max-width: 1024px) {
  .model-list-main-container {
    margin: 10px 0px 35px;
  }
  .model-list-main-loading-container {
    margin: 10px 0px 10px;
    height: 166px;
    display: flex;
  }

  .quest_list_container {
    /* width: 460px; */
  }
  .chat_page_container {
    margin: 100px 100px 0px;
  }
  .waitlist_joined {
    margin: auto;
    padding: 0 10px;
    width: auto;
    text-align: center;
  }
  .landing_video {
    width: 640px;
    height: 360px;
  }
  .landing_page {
    display: block;
    height: auto;
  }
  .landing_page .left_side {
    width: 100%;
    height: 650px;
    /* height: 750px;  */
    position: relative;
  }

  .landing_page .right_side {
    padding: 10px;
    width: 100%;
    background-color: rgb(146, 207, 213);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .landing_page .right_side_waitlist {
    padding: 10px;
    width: 100%;
    background-color: rgb(146, 207, 213);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  .landing_page .bottom_text {
    position: absolute;
    bottom: 40px;
  }
  /* .landing_page .nestheads_link {
    position: absolute;
    bottom: 70px;
    color: white;
    display: block;
    flex: auto;
  } */

  /* .landing_page .bottom_text {
    position: relative;
  } */
}
@media (max-width: 968px) {
  .main_model_image {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }

  .main_model_image_text {
    font-size: 35px;
    line-height: 40px;
    font-family: Dimbo;
    margin: 0px 20px;
  }
  .quest_list_container {
    /* width: 360px; */
  }
  .homepage_slider_time {
    font-size: 13px;
  }
  .quest_slider_model_seo {
    font-size: 18px;
  }
  .quest_header_user_img {
    width: 50px;
    height: 70px;
    object-fit: contain;
    /* border-radius: 50%; */
  }

  .quest_header_img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
  }

  .quest_tags_list {
    overflow-x: scroll;
    margin: 25px 0px;
  }
  .quest_tags_list > img {
    max-width: 50px;
    width: 50px;
    height: 50%;
    object-fit: contain;
    margin-right: 20px;
  }
  .header-link {
    margin: 0px 25px;
  }
  .header-link-active {
    padding: 0 25px;
  }
  .goal_title {
    font-family: "Dimbo";
    font-size: 20px;
    height: 30px;
    min-width: 80px;
    overflow: hidden;
    width: 150px;
  }
  .slide-pane_from_left.content-after-open {
    width: 45% !important;
  }
  .logo {
    padding: 5px 60px;
  }
  .msg_container {
    display: flex;
    margin: 5px 0px;
    max-width: 70%;
  }
  .msg_container_static_message {
    display: flex;
    margin: 5px 0px;
    max-width: 70%;
  }

  .nav {
    background-color: #92cfd5;
    padding: 10px 60px;
  }
  .login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .chat_page {
    margin: 100px 60px 0px;
  }
  .page_container {
    /* margin: 125px 60px 0px; */
  }
  .subscriptions_page {
    margin: 100px 60px 0px;
  }
  .model_page {
    margin: 100px 60px 0px;
  }
  .character_details .img {
    display: flex;
    margin: 0px 50px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
  }
}

@media (max-width: 767px) {

  

  .main_model_dailog_container {
    margin-top: 116px;
  }

  .model-page-dailoge {
    display: flex;
    width: 70%;
    max-width: 1280px;
    margin: auto;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px;
  }
  .model-page-dailoge .default-dialog-image-container {
    min-width: 100px;
    width: 100px;
    min-height: 100px;
    height: 100px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(0, 0, 0);
  }

  .default-dailog-character-name {
    font-size: 25px;
    margin-bottom: 5px;
  }
  .default-dailog-character-des {
    font-size: 20px;
    line-height: 23px;
  }
  .create_nest_heading {
    display: initial;
  }
  .character_items_container {
    flex-direction: column;
  }

  .chat_page_container {
    margin: 125px 50px 0px;
  }
  .quest_list_container {
    /* width: 420px; */
  }
  .custom_buttons {
    height: 45px;
    line-height: 45px;
  }
  .character_filter {
    border-radius: 40px;
    min-width: 120px;
    height: 45px;
  }
  .quest_slider_model_seo {
    font-size: 22px;
  }
  .quest_header_user_img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    /* border-radius: 50%; */
  }
  .quest_header_img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
  }
  .homepage_slider_time {
    font-size: 16px;
  }
  .main_character_container {
    top: 108px;
  }

  .chatPage_sibebar_container {
    width: 70px;
    min-width: 70px;
  }
  .chatPage_sibebar {
    width: 70px;
    padding: 20px 10px 22vh;
    z-index: 99;
    border-right: 1px solid black;
    position: fixed;
    top: 50px;
    height: 100vh;
    overflow: scroll;
  }

  .chatPage_sibebar_tagname {
    display: none;
  }

  .slide-pane_from_left.content-after-open {
    width: 40% !important;
  }
  .landing_video {
    width: 480px;
    height: 270px;
  }
  .model_nestheads {
    width: 70%;
  }
  .logo {
    padding: 5px 5px;
    border-bottom: none;
  }
  .msg_container {
    display: flex;
    margin: 5px 0px;
    max-width: 75%;
  }
  .msg_container_static_message {
    display: flex;
    margin: 5px 0px;
    max-width: 75%;
  }

  .nav {
    background-color: #92cfd5;
    padding: 10px 20px;
  }
  .login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .chat_field {
    padding: 0px 20px 20px;
    padding-left: 90px;
  }
  .chat_page {
    margin: 100px 20px 0px;
  }
  .page_container {
    /* margin: 125px 20px 0px; */
  }
  .subscriptions_page {
    margin: 100px 20px 0px;
  }
  .model_page {
    margin: 100px 20px 0px;
  }

  .character_details .img {
    display: flex;
    margin: 0px 30px;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
  }

  .settings-forms {
    display: block;
  }

  .settings-forms-single-element {
    padding: 0px 10px;
    width: auto;
  }
  .settings-header {
    margin: 10px;
  }
  .my_nestheads {
    border: 3px solid black;
    padding: 50px;
    margin: 0px;
    border-radius: 20px;
  }

  .plans_container {
    grid-template-columns: 1fr;
  }

  .cancel_button {
    position: absolute;
    bottom: -100px;
    width: 100%;
  }

  .plan_container_main_margin {
    margin-bottom: 100px;
  }

  .plan_container_main_margin_top {
    margin-top: 0px;
  }
  .custom_model {
    padding: 5vh 5vw;
    border-radius: 10px;
    background-color: white;
    inset: 0px;
    text-align: center;
    width: 70%;
  }
  .custom_model_quest {
    padding: 5vh 5vw;
    border-radius: 10px;
    background-color: white;
    inset: 0px;
    text-align: center;
    width: 70%;
  }

  .plan_expired {
    grid-template-columns: 1fr;
  }

  .payment_model {
    width: 80%;
  }
  .payment_history_margin {
    font-family: Dimbo;
    font-size: 35px;
    margin: 120px 0px 30px;
    text-align: center;
  }

  .subscriptions_page_plans {
    grid-template-columns: 1fr;
  }

  .plans_container_subscribed {
    width: fit-content;
    max-width: 400px;
    margin: auto;
  }
}

@media (max-width: 680px) {
  .add_items_mainconainer_small {
    display: flex;
    justify-content: space-between;
  }
  .add_items_mainconainer_small .create_nest_btn {
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    margin: 10px 0px;
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 0px;
    border: 2px solid rgb(0, 0, 0);
    background-color: red;
    border-radius: 80px;
  }
  .add_items_mainconainer_small .add_character_item {
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    margin: 10px 0px;
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 0px;
    border: 2px solid rgb(0, 0, 0);
    background-color: red;
    border-radius: 80px;
  }
  .add_items_mainconainer .left_item {
    display: none;
    justify-content: flex-start;
    margin: 30px 0px;
    flex: 1 1 0%;
  }
  .add_items_mainconainer .right_item {
    flex: 1 1 0%;
    display: none;
    justify-content: flex-end;
  }
  .main_character_details_container .right_container {
    height: auto;
    padding-left: 0px;
    padding-top: 20px;
  }
  .main_model_image {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  .main_model_image_text {
    font-size: 30px;
    line-height: 35px;
    font-family: Dimbo;
    margin: 0px 20px;
  }

  .main_model_image_sec_text {
    font-size: 20px;
    font-family: Dimbo;
    margin: 20px 0px;
    text-align: center;
  }

  .create_character .character_details {
    flex-direction: column;
  }
  .create_character .character_details .toggle_container {
    flex-direction: column-reverse;
  }

  .create_character .main_character_details_container {
    /* background-color: rgb(0, 0, 0); */
    padding: 75px;
  }

  .create_character .character_details .character_image_inner_container {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid rgb(0, 0, 0);
    align-items: center;
    position: absolute;
    width: 160px;
    height: 160px;
    background-color: red;
  }

  .second_container .toxic_healthy_input_container {
    height: 120px;
  }
  .second_container .toxic_healthy_input_container .character_image_container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .second_container
    .toxic_healthy_input_container
    .character_image_container
    .character_image {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  .second_container .character_image {

    width: 120px;
    height: 120px;
  }
  .second_container .character_image img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .range-slider {
    width: 150px;
  }
  .mainslidercontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .slidercontainer {
    display: flex;
    align-items: center;
    margin-left: 0px;
  }
  .quest_list_container {
    /* width: 380px; */
  }
  .homepage_slider_time {
    font-size: 14px;
  }
  .quest_slider_model_seo {
    font-size: 16px;
  }
  .homepage_quest_tags_list {
    display: flex;
    overflow-x: scroll;
    margin: 25px 0px;
  }

  .homepage_quest_tags_list > img {
    max-width: 80px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-right: 30px;
  }
  .chat_page_container {
    margin: 125px 50px 0px;
  }
  .slide-pane_from_left.content-after-open {
    width: 50% !important;
  }
  .percentage_controller {
    /* display: none; */
    height: 8px;
    background-color: rgb(238, 238, 238);
    width: 100%;
    border-radius: 5px;
  }
  .percentage_component {
    width: fit-content;
  }
}

@media (max-width: 540px) {
  .custom-slider-buttons {
    padding: 0 10px;
  }
  .custom-prev-button,
  .custom-next-button {
    background-color: #000000; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none; /* Remove button border */
    padding: 2px 6px; /* Padding inside the button */
    cursor: pointer; /* Pointer cursor on hover */
    outline: none; /* Remove outline on focus */
    border-radius: 5px; /* Rounded corners */
  }
  .custom-slider-count-component {
    font-size: 15px;
  }
  .main_model_image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .main_model_image_text {
    font-size: 25px;
    line-height: 30px;
    font-family: Dimbo;
    margin: 0px 20px;
  }
  .range-slider {
    width: 120px;
  }
  .models_loading_container {
    width: 120px;
    height: 105px;
  }
  .upload_character_image {
    min-width: 60px;
    width: 60px;
    height: 60px;
    min-height: 60px;
  }
  .character_category_container_slider .image_container {
    height: 60px;
    /* width: 120px; */
  }
  .character_category_container .image_container {
    height: 60px;
    width: 120px;
  }
  .quest_list_container {
    /* width: 270px; */
  }
  .character_details .img {
    display: flex;
    margin: 0px 15px;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    min-width: 150px;
  }
  .quest_header_user_img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    /* border-radius: 50%; */
  }
  .quest_icons > svg {
    width: 20px;
    height: 20px;
  }

  .homepage_footer {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homepage_quest_tags_list {
    display: flex;
    overflow-x: scroll;
    margin: 20px 0px;
  }

  .homepage_quest_tags_list > img {
    max-width: 60px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 20px;
  }
  .homepage_quest_header_img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
  }
  .quest_header_img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
  }
  .homepage_quest_header_user_img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
  }

  .homepage_slider_time {
    font-size: 12px;
  }
  .homepage_slider_model_seo {
    font-size: 12px;
  }
  .quest_slider_model_seo {
    font-size: 14px;
  }
  .homepage_slider_model_username {
    font-size: 12px;
  }
  .model_not_available {
    width: 200px;
    background-color: rgb(250, 250, 250);
    border-radius: 150px;
    height: 200px;
    margin: 0px auto;
  }

  .message_img {
    margin: 4px;
    object-fit: cover;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
  }
  .slide-pane_from_left.content-after-open {
    width: 80% !important;
  }
  .landing_video {
    width: 480px;
    height: 270px;
  }
  .model_nestheads {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .create_character .main_character_details_container {
    /* background-color: rgb(0, 0, 0); */
    padding: 45px;
  }
  .model-page-dailoge {
    display: flex;
    width: 80%;
    max-width: 1280px;
    margin: auto;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 20px;
  }
  .model-page-dailoge .default-dialog-image-container {
    min-width: 70px;
    width: 70px;
    min-height: 70px;
    height: 70px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(0, 0, 0);
  }

  .default-dailog-character-name {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .default-dailog-character-des {
    font-size: 15px;
    line-height: 18px;
  }
  .character_items_container {
    width: 100%;
  }
  .create_character_fields input {
    width: 100%;
  }
  .create_character_bottom_container {
    flex-direction: column;
  }
  .create_character_bottom_container .left {
    width: auto;
    min-width: auto;
  }
  .create_character_bottom_container .right {
    width: auto;
    min-width: auto;
  }
  .quest_list_container {
    /* width: 270px; */
  }
  .quest_slider_model_seo {
    font-size: 12px;
  }
  .goal_title {
    font-family: "Dimbo";
    font-size: 20px;
    height: 30px;
    min-width: 80px;
    overflow: hidden;
    width: 80px;
  }
  .custom_model_buttons_container {
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }
  .input_field_selected_image {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  .input_field_selected_video {
    width: 20px;
  }

  .input_field_selected_file {
    width: 20px;
    height: 20px;
    background: #fff;
    padding: 10px;
    font-size: 14px;
    overflow: "hidden";
  }
  .chat_page_container {
    margin: 125px 20px 0px;
  }
  .mynestheads_container {
    margin: 125px 20px 0px;
  }

  .chat_field_static {
    padding: 10px 5px 20px 5px !important;
    /* padding-left: 0px !important; */
  }
  .chat_page_container_static {
    margin: 125px 0px 0px;
    padding: 10px 5px 0px;
  }

  .landing_video {
    width: 340px;
    height: 190px;
  }
  .landing_page .left_side {
    width: 100%;
    height: 550px;
    position: relative;
  }
  .logo .logo-img {
    width: 140px;
    height: 40px;
  }
  .msg_container {
    display: flex;
    margin: 5px 0px;
    max-width: 85%;
  }
  .msg_container_static_message {
    display: flex;
    margin: 5px 0px;
    max-width: 85%;
  }
  .about_character_active {
    background-color: rgba(146, 207, 213, 1);
    position: absolute;
    left: 55%;
    top: 15%;
    padding: 5px 10px;
    border-radius: 10px;
    width: 200px;
    font-size: 12px;
  }
  .starting_box {
    bottom: 60px;
    padding: 25px;
    line-height: 28px;
  }
  /* .chat_img_container img {
    height: 200px;
  } */

  .chat_field {
    padding: 0px 20px 20px;
    padding-left: 90px;
  }

  .character_details {
    display: flex;
    flex-direction: column;
  }

  .character_details .title {
    font-size: 30px;
  }

  .chat_character_image {
    height: 180px;
    margin: auto;
  }

  .chat_character_image_small {
    max-width: 150px;
    height: 150px;
    margin: auto;
  }

  .starting_box_small {
    line-height: 28px;
  }
  .star-icon {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
  .star-icon img {
    width: 15px;
    height: 15px;
  }

  .character-image-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
  }
  .character img {
    max-width: 50px;
    max-height: 50px;
  }
  .charater_title {
    font-family: Dimbo;
    font-size: 30px;
    line-height: 35px;
  }

  .header-right .header-right-btn {
    padding: 8px 10px;
    border-radius: 25px;
    background-color: white;
    color: black;
    cursor: pointer;
    font-family: Dimbo;
  }
  .header-right .active {
    padding: 6px 15px;
  }
  .settings-bottom-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .settings-header {
    margin: 10px;
  }

  .settings-btn-cancle {
    padding: 10px;
  }

  .picture-btn {
    display: block;
    font-size: 16px;
  }
  .picture-btn-reset {
    margin-left: 0px;
    margin-top: 10px;
  }

  .settings-profile-pic {
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 30px;
  }

  .settings .setting-tabs {
    font-size: 14px;
  }
  .settings .setting-tabs-active {
    font-size: 14px;
  }
  .settings-profile-pic {
    margin-right: 20px;
  }
}
@media (max-width: 360px) {
  .quest_list_container {
    /* width: 250px; */
  }
}

/* .chat-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 100;
} */

